import Bugsnag from '@bugsnag/js';
import className from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
    useEffect, useRef, useState
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TiktokPixel from 'tiktok-pixel';

import { getDevice } from '@/api/device';
import { dstUrl } from '@/api/dst';
import { errorToString } from '@/api/error';
import type { LoginBody, UsersBody } from '@/api/supreme-api-sdk/src';
import type { ButtonRef } from '@/components/Button';
import { Button } from '@/components/Button';
import { type ComponentSectionsFormAndImage, Enum_Componentsectionsformandimage_Layout, Enum_Componentsectionsformandimage_Type } from '@/generated/graphql-types';
import { userApiClient } from '@/pages/_app';
import { Logo } from '@/templates/Logo';
import { If } from '@/utils/If';
import { getQueryVariable, useUrlQuery } from '@/utils/routing';
import {
    EventType, SignUpStep, trackEvent
} from '@/utils/trackEvent';

import styles from './FormAndImageView.module.scss';

type IFormAndImageViewProps = {
    data: ComponentSectionsFormAndImage;
    pageContext: {
        RedirectionTarget?: string
    };
};

type Inputs = {
    name: string;
    email: string;
    email_confirm: string;
    password: string;
    token?: string;
};

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

const FormAndImageView = observer((props: IFormAndImageViewProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<Inputs>();
    const buttonRef = useRef<ButtonRef>(null);

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => setIsCopied(false), 2500);
        }
    }, [isCopied]);

    const router = useRouter();
    const query = useUrlQuery();

    const login = async (data: LoginBody) => {
        buttonRef?.current?.startLoading();
        try {
            const { data: userData } = await userApiClient.login({
                ...data,
                device: await getDevice(),
                from: props.data.From || getQueryVariable('from') as string
            });
            // toast.success(`Hi ${userData?.user?.full_name ?? ''}, Welcome back`, {
            //     position: 'bottom-center',
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     progress: undefined,
            //     theme: 'dark',
            // });

            let targetUrl = new URL('platforms', document.baseURI).href;
            if (props.data.ForceRedirectTo && props.data.ForceRedirectTo !== '') {
                targetUrl = props.data.ForceRedirectTo;
            } else if (getQueryVariable('redirect')) {
                targetUrl = getQueryVariable('redirect') as string;
            }

            if (getQueryVariable('internal')) {
                router.push(getQueryVariable('redirect') as string);
            } else {
                location.assign(dstUrl(userData.dst!, targetUrl));
            }
        } catch (error: any) {
            const errorString = await errorToString(error);
            Bugsnag.notify(Error(errorString), async (event: any) => {
                event.addMetadata('action', 'register');
                event.addMetadata('formPayload', {
                    ...data,
                    device: await getDevice(),
                    from: props.data.From || getQueryVariable('from') as string
                });
            });
            buttonRef?.current?.stopLoading();
            toast.error(errorString, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: 'dark',
            });
            setIsLoading(false);
        }
    };

    const getToken = () => new Promise<string>((resolve) => {
        (window as any).grecaptcha.enterprise.ready(async () => {
            resolve((window as any).grecaptcha.enterprise.execute('6LeL-yciAAAAAP0MHwC6OYrrJG1vATsij6jVFYKh', { action: 'login' }));
        });
    });

    const signUp = async (data: UsersBody) => {
        buttonRef?.current?.startLoading();
        try {
            const token = await getToken();
            const { data: userData } = await userApiClient.signup({
                token,
                ...data,
                device: await getDevice(),
                from: props.data.From || getQueryVariable('from') as string || 'web'
            });
            // toast.success(`Hi ${userData.user.full_name}, Welcome!`, {
            //     position: 'bottom-center',
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     progress: undefined,
            //     theme: 'dark',
            // });
            try {
                if ((window as any).fbq) {
                    (window as any).fbq('track', 'Lead', {
                        value: 0.00,
                        currency: 'USD',
                        content_name: 'Sign Up',
                        content_category: 'Sign Up Page'
                    });
                }
                TiktokPixel.track('CompleteRegistration', {});
                // eslint-disable-next-line no-empty
            } catch (error) {
            }

            let targetUrl = new URL('platforms', document.baseURI).href;
            if (props.data.ForceRedirectTo && props.data.ForceRedirectTo !== '') {
                targetUrl = props.data.ForceRedirectTo;
            } else if (getQueryVariable('redirect')) {
                targetUrl = getQueryVariable('redirect') as string;
            }

            location.assign(dstUrl(userData.dst!, targetUrl));

            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error: any) {
            const errorString = await errorToString(error);
            Bugsnag.notify(Error(errorString), async (event: any) => {
                event.addMetadata('action', 'register');
                event.addMetadata('formPayload', {
                    ...data,
                    device: await getDevice(),
                    from: props.data.From || getQueryVariable('from') as string || 'web'
                });
            });
            buttonRef?.current?.stopLoading();
            toast.error(errorString, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: 'dark',
            });
            setIsLoading(false);
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
        if (isLoading) { return; }
        setIsLoading(true);
        props.data.Type === 'Login' ? login(data) : signUp(data);
    };

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                handleSubmit(onSubmit)();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    useEffect(() => {
        if (props.data.Type === Enum_Componentsectionsformandimage_Type.Register) {
            trackEvent({
                type: EventType.signUpStep,
                data: {
                    step: SignUpStep.landingPage,
                    platform: props.pageContext.RedirectionTarget?.toLowerCase(),
                    ...(props.data.PromoCode && { promo_code: props.data.PromoCode })
                }
            });
        }
    }, [props.data.Type, props.data.From]);

    const mobileImage = props.data.MobileImage?.data?.attributes || props.data.Image?.data?.attributes;
    const mainImage = props.data.Image?.data?.attributes;

    return (
        <div className={className(`flex min-h-screen items-center justify-center overflow-x-hidden ${props.data.Layout !== Enum_Componentsectionsformandimage_Layout.Stats && 'pt-[12.5rem]'} `, styles.wrap)}>
            <If condition={props.data.Layout === Enum_Componentsectionsformandimage_Layout.BackgroundMedia}>
                {mainImage?.mime.startsWith('video') ? <video src={mainImage?.url} playsInline loop muted autoPlay controls={false} className='absolute inset-0 hidden h-full w-full object-cover lg:block' /> : null}
                {!mainImage?.mime.startsWith('video') ? <img src={mainImage?.url} alt='' className='absolute inset-0 hidden h-full w-full object-cover lg:block' /> : null}
                {mobileImage?.mime.startsWith('video') ? <video src={mobileImage?.url} playsInline loop muted autoPlay controls={false} className='absolute inset-0 h-full w-full object-cover lg:hidden' /> : null}
                {!mobileImage?.mime.startsWith('video') ? <img src={mobileImage?.url} alt='' className='absolute inset-0 h-full w-full object-cover lg:hidden' /> : null}
            </If>
            <If condition={[Enum_Componentsectionsformandimage_Layout.BackgroundMedia, Enum_Componentsectionsformandimage_Layout.FullImage].includes(props.data.Layout)}>
                <div
                    className={
                        className('header fixed inset-x-0 top-0 z-10 flex flex-row justify-between p-8 md:p-16', props.data.Layout === Enum_Componentsectionsformandimage_Layout.BackgroundMedia ? 'bg-transparent' : 'bg-black')
                    }
                >
                    <Link href="/">
                        <a>
                            <Logo width={152.8} height={32} showText={true} />
                        </a>
                    </Link>
                    <img
                        onClick={() => router.push('/')}
                        className={'cursor-pointer hover:opacity-80'}
                        src={'/assets/icons/popUpClose.svg'}
                        alt='Close'
                        width={45}
                        height={45}
                    />
                </div>
            </If>
            <div
                className={
                    [Enum_Componentsectionsformandimage_Layout.BackgroundMedia, Enum_Componentsectionsformandimage_Layout.FullImage].includes(props.data.Layout) ? styles.main : styles.mainStats
                }
            >
                <div
                    className={className(
                        [Enum_Componentsectionsformandimage_Layout.BackgroundMedia, Enum_Componentsectionsformandimage_Layout.FullImage].includes(props.data.Layout)
                            ? styles.formWrapper
                            : styles.statsFormWrapper
                    )}
                >
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={className(
                            styles.mainFormClass,
                            props.data.Layout === Enum_Componentsectionsformandimage_Layout.BackgroundMedia && styles.transparentForm,
                            props.data.Layout === styles.stats && styles.statsForm
                        )}
                    >
                        <span
                            className={className('text-[4.5rem]', 'leading-loginTitle')}
                            dangerouslySetInnerHTML={{ __html: props.data.Title }}
                        />
                        <If condition={props.data.SmallText}>
                            <span
                                className={
                                    ' mt-12 block  text-xl leading-[2.6rem]  text-bpm-white opacity-50'
                                }
                            >
                                {props.data.SmallText}
                            </span>
                        </If>
                        <div className={'md:mt-10'}>
                            <If condition={props.data.Type === 'Register'}>
                                <div className={styles.group}>
                                    <input
                                        type="text"
                                        className={styles.input}
                                        placeholder={' '}
                                        id={'name'}
                                        {...register('name', {
                                            required: props.data.Type === 'Register',
                                        })}
                                    />
                                    <label className={styles.label} htmlFor="name">
                                        Full Name
                                    </label>
                                    <div className={styles.bar}></div>
                                    <AnimatePresence>
                                        {errors.name && (
                                            <motion.span
                                                variants={variants}
                                                initial="hidden"
                                                transition={{ duration: '0.5' }}
                                                animate="enter"
                                                exit="exit"
                                                className={'mt-2 block text-bpm-supreme'}
                                            >
                                                This field is required
                                            </motion.span>
                                        )}
                                    </AnimatePresence>
                                </div>
                            </If>

                            <div className={styles.group}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    placeholder={' '}
                                    id={'email'}
                                    {...register('email', { required: true })}
                                />
                                <label className={styles.label} htmlFor="email">
                                    Email
                                </label>
                                <div className={styles.bar}></div>
                                <AnimatePresence>
                                    {errors.email && (
                                        <motion.span
                                            variants={variants}
                                            initial="hidden"
                                            transition={{ duration: '0.5' }}
                                            animate="enter"
                                            exit="exit"
                                            className={'mt-2 block text-bpm-supreme'}
                                        >
                                            This field is required and a valid Email
                                        </motion.span>
                                    )}
                                </AnimatePresence>
                            </div>
                            <If condition={props.data.Type === 'Register'}>
                                <div className={styles.group}>
                                    <input
                                        className={styles.input}
                                        type={'text'}
                                        placeholder={' '}
                                        id={'email_confirm'}
                                        {...register('email_confirm', {
                                            required: props.data.Type === 'Register',
                                            validate: props.data.Type === 'Register' ? (value) => value?.toLowerCase()?.trim() === getValues().email?.toLowerCase()?.trim() : undefined
                                        })}
                                    />
                                    <label className={styles.label} htmlFor="email_confirm">
                                        Confirm Email
                                    </label>
                                    <div className={styles.bar}></div>
                                    <AnimatePresence>
                                        {errors.email_confirm && (
                                            <motion.span
                                                variants={variants}
                                                initial="hidden"
                                                transition={{ duration: '0.5' }}
                                                animate="enter"
                                                exit="exit"
                                                className={'mt-2 block text-bpm-supreme'}
                                            >
                                                Emails do not match
                                            </motion.span>
                                        )}
                                    </AnimatePresence>
                                </div>
                            </If>

                            <div className={styles.group}>
                                <input
                                    className={styles.input}
                                    type={'password'}
                                    placeholder={' '}
                                    id={'password'}
                                    {...register('password', { required: true })}
                                />
                                <label className={styles.label} htmlFor="password">
                                    Password
                                </label>
                                <div className={styles.bar}></div>
                                <AnimatePresence>
                                    {errors.password && (
                                        <motion.span
                                            variants={variants}
                                            initial="hidden"
                                            transition={{ duration: '0.5' }}
                                            animate="enter"
                                            exit="exit"
                                            className={'mt-2 block text-bpm-supreme'}
                                        >
                                            This field is required
                                        </motion.span>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>

                        <If
                            condition={
                                props.data.Type === 'Register'
                                && [Enum_Componentsectionsformandimage_Layout.BackgroundMedia, Enum_Componentsectionsformandimage_Layout.FullImage].includes(props.data.Layout)
                            }
                        >
                            <span
                                className={
                                    'mt-16 block text-xl leading-[2.6rem] text-bpm-white opacity-50'
                                }
                            >
                                By creating an account you accept our <a className={'underline'} href={'/terms'}>
                                    Terms of Service
                                </a> and{' '}
                                <a className={'underline'} href={'/privacy'}>
                                    Privacy Statement
                                </a>
                                .
                            </span>
                        </If>

                        <If
                            condition={
                                props.data.Type === 'Register'
                                && props.data.PromoCode
                            }
                        >
                            <div
                                className={
                                    'mt-[2rem] bg-bpm-darkGray px-[1.7rem] pt-[1.7rem] pb-[1.2rem]'
                                }
                            >
                                <div>
                                    <span className={'mr-4 text-base text-bpm-gray opacity-90'}>
                                        Use this code on the checkout
                                    </span>
                                    <span
                                        className={
                                            'inline-block h-[1.7rem] w-[1.7rem] rounded-[5rem] bg-bpm-gray text-center leading-[1.6rem] text-bpm-darkGray'
                                        }
                                    >
                                        i
                                    </span>
                                </div>
                                <div className={'relative -mt-2 flex flex-row justify-between'}>
                                    <span className={'text-2xl-m '}>{props.data.PromoCode}</span>
                                    <CopyToClipboard
                                        text={props.data.PromoCode as any}
                                        onCopy={() => setIsCopied(true)}
                                    >
                                        <img
                                            className={'!max-w-[19.83px] cursor-pointer'}
                                            src={'/assets/icons/copy.svg'}
                                            alt='Copy'
                                            width={19.83}
                                            height={19.84}
                                        />
                                    </CopyToClipboard>
                                    <AnimatePresence>
                                        <If condition={isCopied}>
                                            <motion.span
                                                initial="hidden"
                                                animate="enter"
                                                exit="exit"
                                                variants={{
                                                    hidden: { opacity: 0 },
                                                    enter: { opacity: 1 },
                                                    exit: { opacity: 0 },
                                                }}
                                                className={'absolute left-[0] top-[-3.6rem] text-sm'}
                                            >
                                                Promo copied to clipboard!
                                            </motion.span>
                                        </If>
                                    </AnimatePresence>
                                </div>
                            </div>
                        </If>

                        <Button
                            ref={buttonRef}
                            animated
                            className={'mt-8 w-full'}
                            onClick={() => handleSubmit(onSubmit)()}
                        >
                            {props.data.Type === 'Register' ? 'Sign Up' : 'Login'}
                        </Button>

                        <If condition={props.data.OfferDisclaimer
                            && [Enum_Componentsectionsformandimage_Layout.BackgroundMedia, Enum_Componentsectionsformandimage_Layout.FullImage].includes(props.data.Layout)}>
                            <div className={'mx-auto w-full max-w-[115rem]'}>
                                <p
                                    className={
                                        'my-[2rem] max-w-[62.7rem] text-base leading-[1.8rem] text-bpm-white opacity-50'
                                    }
                                >
                                    {props.data.OfferDisclaimer}
                                </p>
                            </div>
                        </If>

                        <If condition={props.data.Type === 'Login'}>
                            <span
                                className={
                                    'mt-16 block text-xl leading-[2.6rem] text-bpm-white opacity-50'
                                }
                            >
                                Don&apos;t have an account yet?&nbsp;
                                <Link href={{ pathname: '/signup', query }}>
                                    <a className={'underline'}>
                                        Register here
                                    </a>
                                </Link>
                                .<br />
                                <Link href={{ pathname: '/forgot-password', query }}>
                                    <a className={'underline'}>
                                        Forgot your password?
                                    </a>
                                </Link>
                            </span>
                        </If>

                        <If condition={props.data.Type === 'Register'}>
                            <span
                                className={
                                    'mt-8 block text-xl leading-[2.6rem] text-bpm-white opacity-50'
                                }
                            >
                                Already have an account?&nbsp;
                                <Link href={{ pathname: props.data.LoginLink ? props.data.LoginLink : '/login', query }}>
                                    <a className={'underline'}>
                                        Log in
                                    </a>
                                </Link>
                            </span>
                        </If>
                    </form>

                    <div className={styles.imageWrapper}>
                        <If condition={props.data.Layout !== Enum_Componentsectionsformandimage_Layout.BackgroundMedia}>
                            <img
                                className={
                                    className(
                                        props.data.Layout === Enum_Componentsectionsformandimage_Layout.FullImage
                                            ? styles.mainImage
                                            : styles.smallImage
                                    )
                                }
                                alt=''
                                src={props.data.Image?.data?.attributes?.url}
                            />
                        </If>
                        <If condition={props.data.Layout === Enum_Componentsectionsformandimage_Layout.Stats}>
                            <div
                                className={
                                    'statsWrapper mt-[5.8rem] flex w-full flex-row justify-around px-24'
                                }
                            >
                                <div className={'flex flex-col'}>
                                    <span className={'text-2xl leading-heroDesc text-bpm-white'}>
                                        4000+
                                    </span>
                                    <span className={'text-2xl leading-heroDesc text-bpm-gray'}>
                                        Sound Packs
                                    </span>
                                </div>
                                <div className={'flex flex-col'}>
                                    <span className={'text-2xl leading-heroDesc text-bpm-white'}>
                                        600k*
                                    </span>
                                    <span className={'text-2xl leading-heroDesc text-bpm-gray'}>
                                        Samples
                                    </span>
                                </div>
                                <div className={'flex flex-col'}>
                                    <span className={'text-2xl leading-heroDesc text-bpm-white'}>
                                        10k+
                                    </span>
                                    <span className={'text-2xl leading-heroDesc text-bpm-gray'}>
                                        MIDI files
                                    </span>
                                </div>
                            </div>
                        </If>
                    </div>
                    <If condition={[Enum_Componentsectionsformandimage_Layout.BackgroundMedia, Enum_Componentsectionsformandimage_Layout.FullImage].includes(props.data.Layout)}>
                        <div className={styles.shadow} />
                    </If>
                </div>
                <If condition={props.data.OfferDisclaimer && props.data.Layout === Enum_Componentsectionsformandimage_Layout.Stats}>
                    <div className={'mx-auto w-full max-w-[115rem]'}>
                        <p
                            className={
                                'mt-[14rem] max-w-[62.7rem] p-16 text-base leading-[1.8rem] text-bpm-white opacity-50'
                            }
                        >
                            {props.data.OfferDisclaimer}
                        </p>
                    </div>
                </If>
            </div>
        </div>
    );
});

export { FormAndImageView };
